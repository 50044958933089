import { SeasonStatuses } from '../generated/graphql';
import { UserContextState } from '../store/user-context/user-context.store.types';
import { CompetitionTeamKitSearchFilters } from '../types/competition-team-kits.filters.types';
import {
  AdminManageCompetitionIntegrationRequestSearchFilters,
  CompetitionIntegrationRequestSearchFilters,
  CompetitionSearchFilters,
  CompetitionSummariesSearchFilters,
} from '../types/competitions.filters.types';
import { KeyIncidentSummarySearchFilters } from '../types/key-incidents.filters.types';
import {
  OrgOfficialCompettionRatingsSearchFilters,
  OrgOfficialFeedbackSearchFilters,
} from '../types/officials.filters.types';
import { OrganizationParticipationSummarySearchFiltersType } from '../types/org-participation.filters.types';
import { ParticipationDisplayType } from '../types/participation.types';
import { TeamSearchFilters } from '../types/teams.filters.types';
import { UserAlertSearchFilters } from '../types/users.filters.types';

// #region Generic

export const setStateLocalStorage = <T>(key: string, value: T) => {
  if (typeof value === 'string') {
    localStorage.setItem(key, value);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
};
export const getStateLocalStorage = <T>(key: string): T | null => {
  const raw = window.localStorage.getItem(key);
  if (!raw) return null;
  try {
    return JSON.parse(raw);
  } catch (ex) {
    return (raw as unknown) as T;
  }
};
export const clearStateLocalStorage = (key: string) => localStorage.removeItem(key);

// #endregion

// #region Auth

const authLocalStorageKey = '__auth_provider_token__';
export const setAccessToken = (token: string) =>
  setStateLocalStorage<string>(authLocalStorageKey, token);
export const getAccessToken = (): string | null =>
  getStateLocalStorage<string>(authLocalStorageKey);
export const clearAccessToken = () => clearStateLocalStorage(authLocalStorageKey);

// #endregion

// #region User Context

const userContextStateLocalStorageKey = '__user_role_context_token__';
export const setUserContextStateLocalStorage = (state: UserContextState) =>
  setStateLocalStorage<UserContextState>(userContextStateLocalStorageKey, state);
export const getUserContextStateLocalStorage = (): UserContextState | null =>
  getStateLocalStorage<UserContextState>(userContextStateLocalStorageKey);
export const clearUserContextStateLocalStorage = () =>
  clearStateLocalStorage(userContextStateLocalStorageKey);

// #endregion

// #region Filters

// #region KI Summary Filters

const kiSummariesTableFiltersStorageKey = '__ki_summary_table_filters__';
export const setKISummariesTableFiltersStateLocalStorage = (
  state: KeyIncidentSummarySearchFilters,
) =>
  setStateLocalStorage<KeyIncidentSummarySearchFilters>(kiSummariesTableFiltersStorageKey, state);
export const getKISummariesTableFiltersStateLocalStorage = (): KeyIncidentSummarySearchFilters | null =>
  getStateLocalStorage<KeyIncidentSummarySearchFilters>(kiSummariesTableFiltersStorageKey);
export const clearKISummariesTableFiltersStateLocalStorage = () =>
  clearStateLocalStorage(kiSummariesTableFiltersStorageKey);

// #endregion

// #region Competition TeamKit Filters

const competitionTeamKitSummariesTableFiltersStorageKey =
  '__competition_team_kit_summaries_table_filters__';
export const setCompetitionTeamKitSummariesTableFiltersStateLocalStorage = (
  state: CompetitionTeamKitSearchFilters,
) =>
  setStateLocalStorage<CompetitionTeamKitSearchFilters>(
    competitionTeamKitSummariesTableFiltersStorageKey,
    state,
  );
export const getCompetitionTeamKitSummariesTableFiltersStateLocalStorage = (): CompetitionTeamKitSearchFilters | null =>
  getStateLocalStorage<CompetitionTeamKitSearchFilters>(
    competitionTeamKitSummariesTableFiltersStorageKey,
  );
export const clearCompetitionTeamKitSummariesTableFiltersStateLocalStorage = () =>
  clearStateLocalStorage(competitionTeamKitSummariesTableFiltersStorageKey);

// #endregion

// #region Competition Filters

const competitionsForSeasonStorageKey = '__competitions_for_season_filters__';
export const setCompetitionsForSeasonFiltersStateLocalStorage = (state: CompetitionSearchFilters) =>
  setStateLocalStorage<CompetitionSearchFilters>(competitionsForSeasonStorageKey, state);
export const getCompetitionsForSeasonFiltersStateLocalStorage = (): CompetitionSearchFilters | null =>
  getStateLocalStorage<CompetitionSearchFilters>(competitionsForSeasonStorageKey);
export const clearCompetitionsForSeasonsStateLocalStorage = () =>
  clearStateLocalStorage(competitionsForSeasonStorageKey);

// #endregion

// #region All Competition Summaries Filters

const allCompetitionSummariesForSeasonStorageKey =
  '__all_competition_summaries_for_season_filters__';
export const setAllCompetitionSummariesForSeasonFiltersStateLocalStorage = (
  state: CompetitionSummariesSearchFilters,
) =>
  setStateLocalStorage<CompetitionSummariesSearchFilters>(
    allCompetitionSummariesForSeasonStorageKey,
    state,
  );
export const getAllCompetitionSummariesForSeasonFiltersStateLocalStorage = (): CompetitionSummariesSearchFilters | null =>
  getStateLocalStorage<CompetitionSummariesSearchFilters>(
    allCompetitionSummariesForSeasonStorageKey,
  );
export const clearAllCompetitionSummariesForSeasonsStateLocalStorage = () =>
  clearStateLocalStorage(allCompetitionSummariesForSeasonStorageKey);

// #endregion

// #region Competition Integration Request Filters

const competitionIntegrationRequestsForSeasonStorageKey =
  '__competition_integration_requests_for_season_filters__';
export const setCompetitionIntegrationRequestsForSeasonFiltersStateLocalStorage = (
  state: CompetitionIntegrationRequestSearchFilters,
) =>
  setStateLocalStorage<CompetitionIntegrationRequestSearchFilters>(
    competitionIntegrationRequestsForSeasonStorageKey,
    state,
  );
export const getCompetitionIntegrationRequestsForSeasonFiltersStateLocalStorage = (): CompetitionIntegrationRequestSearchFilters | null =>
  getStateLocalStorage<CompetitionIntegrationRequestSearchFilters>(
    competitionIntegrationRequestsForSeasonStorageKey,
  );
export const clearCompetitionIntegrationRequestsForSeasonsStateLocalStorage = () =>
  clearStateLocalStorage(competitionIntegrationRequestsForSeasonStorageKey);

// #endregion

// #region Manage Integrations Filters

const adminCompetitionIntegrationRequestsStorageKey =
  '__competition_integration_requests_for_season_filters__';

export const setAdminCompetitionIntegrationRequestsFiltersStateLocalStorage = (
  state: AdminManageCompetitionIntegrationRequestSearchFilters,
) =>
  setStateLocalStorage<AdminManageCompetitionIntegrationRequestSearchFilters>(
    adminCompetitionIntegrationRequestsStorageKey,
    state,
  );

export const getAdminCompetitionIntegrationRequestsFiltersStateLocalStorage = (): AdminManageCompetitionIntegrationRequestSearchFilters | null =>
  getStateLocalStorage<AdminManageCompetitionIntegrationRequestSearchFilters>(
    adminCompetitionIntegrationRequestsStorageKey,
  );

export const clearAdminCompetitionIntegrationRequestsStateLocalStorage = () =>
  clearStateLocalStorage(adminCompetitionIntegrationRequestsStorageKey);

// #endregion

// #region Teams for Season Filters

const teamsForSeasonStorageKey = '__teams_for_season_filters__';
export const setTeamsForSeasonFiltersStateLocalStorage = (state: TeamSearchFilters) =>
  setStateLocalStorage<TeamSearchFilters>(teamsForSeasonStorageKey, state);
export const getTeamsForSeasonFiltersStateLocalStorage = (): TeamSearchFilters | null =>
  getStateLocalStorage<TeamSearchFilters>(teamsForSeasonStorageKey);
export const clearTeamsForSeasonsStateLocalStorage = () =>
  clearStateLocalStorage(teamsForSeasonStorageKey);

// #endregion

// #region Participation Summary for Org Filters

const participationSummariesForOrgStorageKey = '__participation_summaries_for_org_filters__';
export const setParticipationSummariesForOrgFiltersStateLocalStorage = (
  state: OrganizationParticipationSummarySearchFiltersType,
) =>
  setStateLocalStorage<OrganizationParticipationSummarySearchFiltersType>(
    participationSummariesForOrgStorageKey,
    state,
  );
export const getParticipationSummariesForOrgFiltersStateLocalStorage = (): OrganizationParticipationSummarySearchFiltersType | null =>
  getStateLocalStorage<OrganizationParticipationSummarySearchFiltersType>(
    participationSummariesForOrgStorageKey,
  );
export const clearParticipationSummariesForOrgFiltersStateLocalStorage = () =>
  clearStateLocalStorage(participationSummariesForOrgStorageKey);

// #endregion

// #region Participation Display Type

const participationDisplayTypeStorageKey = '__participation_display_type__';
export const setParticipationDisplayTypeStateLocalStorage = (state: ParticipationDisplayType) =>
  setStateLocalStorage<ParticipationDisplayType>(participationDisplayTypeStorageKey, state);
export const getParticipationDisplayTypeStateLocalStorage = (): ParticipationDisplayType | null =>
  getStateLocalStorage<ParticipationDisplayType>(participationDisplayTypeStorageKey);
export const clearParticipationDisplayTypeStateLocalStorage = () =>
  clearStateLocalStorage(participationDisplayTypeStorageKey);

// #endregion

// #region User Alert Filters

const userAlertFiltersStorageKey = '__user_alert_filters__';
export const setUserAlertFiltersStateLocalStorage = (state: UserAlertSearchFilters) =>
  setStateLocalStorage<UserAlertSearchFilters>(userAlertFiltersStorageKey, state);
export const getUserAlertFiltersStateLocalStorage = (): UserAlertSearchFilters | null =>
  getStateLocalStorage<UserAlertSearchFilters>(userAlertFiltersStorageKey);
export const clearUserAlertFiltersStateLocalStorage = () =>
  clearStateLocalStorage(userAlertFiltersStorageKey);

// #endregion

// #region User Workspace Season Status Filters

const userWorkspaceSeasonStatusFilterStorageKey = '__user_workspace_season_status_filters__';
export const setWorkSpaceSeasonStatusFilterStateLocalStorage = (state: SeasonStatuses | string) =>
  setStateLocalStorage<SeasonStatuses | string>(userWorkspaceSeasonStatusFilterStorageKey, state);
export const getWorkSpaceSeasonStatusFilterStateLocalStorage = (): SeasonStatuses | string | null =>
  getStateLocalStorage<SeasonStatuses | string>(userWorkspaceSeasonStatusFilterStorageKey);
export const clearWorkSpaceSeasonStatusFilterStorage = () =>
  clearStateLocalStorage(userWorkspaceSeasonStatusFilterStorageKey);

// #endregion

// #region Org Official Feedbacks Filters

const orgOfficialFeedbacksStorageKey = '__org_official_feedbacks_filters__';
export const setOrgOfficialFeedbacksFiltersStateLocalStorage = (
  state: OrgOfficialFeedbackSearchFilters,
) => setStateLocalStorage<OrgOfficialFeedbackSearchFilters>(orgOfficialFeedbacksStorageKey, state);
export const getOrgOfficialFeedbacksFiltersStateLocalStorage = (): OrgOfficialFeedbackSearchFilters | null =>
  getStateLocalStorage<OrgOfficialFeedbackSearchFilters>(orgOfficialFeedbacksStorageKey);
export const clearOrgOfficialFeedbacksStateLocalStorage = () =>
  clearStateLocalStorage(orgOfficialFeedbacksStorageKey);

// #endregion

// #region Org Official KI Summary Filters

const orgOfficialKISummariesTableFiltersStorageKey = '__org_official_ki_summary_table_filters__';
export const setOrgOfficialKISummariesTableFiltersStateLocalStorage = (
  state: KeyIncidentSummarySearchFilters,
) =>
  setStateLocalStorage<KeyIncidentSummarySearchFilters>(
    orgOfficialKISummariesTableFiltersStorageKey,
    state,
  );
export const getOrgOfficialKISummariesTableFiltersStateLocalStorage = (): KeyIncidentSummarySearchFilters | null =>
  getStateLocalStorage<KeyIncidentSummarySearchFilters>(
    orgOfficialKISummariesTableFiltersStorageKey,
  );
export const clearOrgOfficialKISummariesTableFiltersStateLocalStorage = () =>
  clearStateLocalStorage(orgOfficialKISummariesTableFiltersStorageKey);

// #endregion

// #region Org Official Competition Ratings Filters

const orgOfficialCompetitionRatingsFiltersStorageKey =
  '__org_official_competition_ratings_filters__';
export const setOrgOfficialCompetitionRatingsFiltersStateLocalStorage = (
  state: OrgOfficialCompettionRatingsSearchFilters,
) =>
  setStateLocalStorage<OrgOfficialCompettionRatingsSearchFilters>(
    orgOfficialCompetitionRatingsFiltersStorageKey,
    state,
  );
export const getOrgOfficialCompetitionRatingsFiltersStateLocalStorage = (): OrgOfficialCompettionRatingsSearchFilters | null =>
  getStateLocalStorage<OrgOfficialCompettionRatingsSearchFilters>(
    orgOfficialCompetitionRatingsFiltersStorageKey,
  );
export const clearOrgOfficialCompetitionRatingsFiltersStateLocalStorage = () =>
  clearStateLocalStorage(orgOfficialCompetitionRatingsFiltersStorageKey);

// #endregion

// #endregion

export const clearLocalStorage = () => {
  localStorage.clear();
};
